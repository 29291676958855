import React, { Fragment, useEffect, useState } from "react";
import Abilities from "./components/Abilities";
import ContactForm from "./components/ContactForm";
import Experiences from "./components/Experiences";
import FixedNavBar from "./components/FixedNavBar";
import FotoSection from "./components/FotoSection";
import PopupComponent from "./components/PopupComponent";
import Projekte from "./components/Projekte";
import "./style/main.css";
import axios from "axios";

//
function App() {
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    setTimeout(async () => {
      setVisibility(false);

      // add start to run backend
      const stateInput = {
        name: "Start to run backend",
        email: "alipudina@test.com",
        userText: "Test",
      };
      try {
        const result = await axios.post(
          "https://ali-pudina-website.herokuapp.com/email/",
          stateInput
        );
        console.log(result.data.message);
      } catch (error) {
        console.log("client: ", error);
      }
    }, 4000);
  }, []);

  return (
    <Fragment>
      {visibility && <PopupComponent />}

      {!visibility && <FixedNavBar />}
      {!visibility && <FotoSection />}
      {!visibility && <Experiences />}
      {!visibility && <Abilities />}
      {!visibility && <Projekte />}
      {!visibility && <ContactForm />}
    </Fragment>
  );
}

export default App;
