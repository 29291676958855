import React, { useEffect } from "react";
import { Link } from "react-scroll";

function FixedNavBar(props) {
  useEffect(() => {
    setTimeout(() => {
      let prevScrollpos = window.pageYOffset;
      let fixedNavbar = document.querySelector(".fixedNavBar");
      window.onscroll = function () {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
          fixedNavbar.style.top = "0";
        } else {
          fixedNavbar.style.top = "-10rem";
        }
        prevScrollpos = currentScrollPos;
      };
    }, 100);
  });

  // let prevScrollpos = window.pageYOffset;
  // let fixedNavbar = document.querySelector(".fixedNavBar");
  // window.onscroll = function () {
  //   let currentScrollPos = window.pageYOffset;
  //   if (prevScrollpos > currentScrollPos) {
  //     fixedNavbar.style.top = "0";
  //   } else {
  //     fixedNavbar.style.top = "-10rem";
  //   }
  //   prevScrollpos = currentScrollPos;
  // };

  return (
    <div className="fixedNavBar">
      <Link
        className="navbarLinks"
        activeClass="active"
        to="profile"
        spy={true}
        smooth={true}
        hashSpy={true}
        offset={0}
        duration={500}
        delay={0}
        isDynamic={true}
        // onSetActive={this.handleSetActive}
        // onSetInactive={this.handleSetInactive}
        ignoreCancelEvents={false}
      >
        Profile
      </Link>

      <Link
        activeClass="active"
        to="erfahrungen"
        spy={true}
        smooth={true}
        hashSpy={true}
        offset={0}
        duration={500}
        delay={0}
        isDynamic={true}
        // onSetActive={this.handleSetActive}
        // onSetInactive={this.handleSetInactive}
        ignoreCancelEvents={false}
      >
        Erfahrungen
      </Link>

      <Link
        activeClass="active"
        to="fähigkeiten"
        spy={true}
        smooth={true}
        hashSpy={true}
        offset={0}
        duration={500}
        delay={0}
        isDynamic={true}
        // onSetActive={this.handleSetActive}
        // onSetInactive={this.handleSetInactive}
        ignoreCancelEvents={false}
      >
        Fähigkeiten
      </Link>

      <Link
        activeClass="active"
        to="projekte"
        spy={true}
        smooth={true}
        hashSpy={true}
        offset={40}
        duration={500}
        delay={0}
        isDynamic={true}
        // onSetActive={this.handleSetActive}
        // onSetInactive={this.handleSetInactive}
        ignoreCancelEvents={false}
      >
        Projekte
      </Link>

      <Link
        activeClass="active"
        to="kontakt"
        spy={true}
        smooth={true}
        hashSpy={true}
        offset={70}
        duration={500}
        delay={0}
        isDynamic={true}
        // onSetActive={this.handleSetActive}
        // onSetInactive={this.handleSetInactive}
        ignoreCancelEvents={false}
      >
        Kontakt
      </Link>
    </div>
  );
}

export default FixedNavBar;
