import React from "react";
// import { ReactComponent as HeartSvg } from "../img/SVG/heart.svg"; 

import { ReactComponent as HeartSvg } from "../img/letter-a.svg";

function PopupComponent(props) {
  return (
    <div className="heart-container">
      <HeartSvg className="heart" />
    </div>
  );
}

export default PopupComponent;
