import axios from "axios";
import React, { useState } from "react";

function ContactForm(props) {
  const [sendCofirmation, setSendCofirmation] = useState(false);
  const [stateInput, setStateInput] = useState({
    name: "",
    email: "",
    userText: "",
  });

  const handleInput = ({ target }) => {
    const formInput = { ...stateInput };

    formInput[target.name] = target.value;

    setStateInput(formInput);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    console.log(stateInput);

    try {
      const result = await axios.post(
        "https://ali-pudina-website.herokuapp.com/email/",
        stateInput
      );
      console.log(result.data.message);
    } catch (error) {
      console.log("client: ", error);
    }

    setSendCofirmation(true);
    console.log(sendCofirmation);
    setTimeout(() => {
      setSendCofirmation(false);
      setStateInput({
        name: "",
        email: "",
        userText: "",
      });
    }, 2000);
  };

  return (
    <div className="contact" id="kontakt">
      <div className="contact__img">
        <form className="form" onSubmit={handleSubmit}>
          <h3 className="form__title">Kontakt</h3>

          <div className="form__group">
            <input
              onChange={(e) => handleInput(e)}
              name="name"
              type="text"
              className="form__input"
              id="name"
              placeholder="Name"
              required
              value={stateInput.name}
            />
            <label htmlFor="name" className="form__label">
              Name
            </label>
          </div>

          <div className="form__group">
            <input
              onChange={(e) => handleInput(e)}
              name="email"
              type="email"
              className="form__input"
              id="email"
              placeholder="Email"
              required
              value={stateInput.email}
            />
            <label htmlFor="email" className="form__label">
              Email Adresse
            </label>
          </div>

          <div className="form__group">
            <textarea
              onChange={(e) => handleInput(e)}
              name="userText"
              className="form__input form__textarea"
              id="textarea"
              placeholder="Ihre Nachricht"
              required
              value={stateInput.userText}
            ></textarea>
            <label htmlFor="textarea" className="form__label">
              Ihre Nachricht
            </label>
          </div>

          <div className="form__group">
            <button type="submit" disabled={false} className="form__button">
              Submit
            </button>
          </div>
        </form>
        {sendCofirmation && (
          <div className="contact__confirmation">
            <h4>Ihre Nachricht wurde gesendet.</h4>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactForm;
