import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import movieApp from "../img/movie-app.png";
import pizzaShop from "../img/pizzaShop-app.png";
import multiplication from "../img/multiplication-app.png";
import weather from "../img/weather-app.png";

function Projekte(props) {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div id="projekte">
      <div className="experiences">
        <div className="experiences__headline">Projekte</div>
        <div className="experiences__border-bottom"></div>
        {/* <div className="experiences__title abilities__title"></div> */}

        <div className="projekte">
          <div className="projekte__app" data-aos="fade-right">
            <a
              className="project-link"
              href="https://ali-movie-app.herokuapp.com/movies"
              target="_blank" rel="noopener noreferrer"
            >
              <p className="project-link__headline">Tech stuck:</p>
              <div className="project-link__line"></div>
              <ul className="project-link__list">
                <li>React js</li>
                <li>React-toastify</li>
                <li>Vanila js</li>
                <li>Bootstrap</li>
                <li>Node js</li>
                <li>Express js</li>
                <li>MongoDB Atlas</li>
                <li>Joi validation</li>
                <li>SASS</li>
                <li>BEM</li>
              </ul>
            </a>
            <div className="image-container">
              <img src={movieApp} alt="Movie app" />
            </div>
            <p>Movie app</p>
          </div>

          <div className="projekte__app" data-aos="fade-left">
            <a className="project-link" href="https://pizza-shop.netlify.app" target="_blank" rel="noopener noreferrer">
              <p className="project-link__headline">Tech stuck:</p>
              <div className="project-link__line"></div>
              <ul className="project-link__list">
                <li>React js</li>
                <li>React-Redux</li>
                <li>Vanila js</li>
                <li>CSS Animate</li>
                <li>SASS</li>
                <li>SVG</li>
              </ul>
            </a>

            <div className="image-container">
              <img src={pizzaShop} alt="Pizza Shop" />
            </div>
            <p>Pizza shop</p>
          </div>
          <div className="projekte__app" data-aos="fade-right">
            <a
              className="project-link"
              href="https://math-multiplication.netlify.com"
              target="_blank" rel="noopener noreferrer"
            >
              <p className="project-link__headline">Tech stuck:</p>
              <div className="project-link__line"></div>
              <ul className="project-link__list">
                <li>HTML</li>
                <li>SASS</li>
                <li>Vanila js</li>
                <li>CSS Animate</li>
                <li>Mediaquery(Mobile)</li>
                <li>CSS Flexbox</li>
                <li>CSS Grid</li>
                <li>BEM</li>
              </ul>
            </a>

            <div className="image-container">
              <img src={multiplication} alt="Multiplication app" />
            </div>
            <p>Multiplication app</p>
          </div>
          <div className="projekte__app" data-aos="fade-left">
            <a className="project-link" href="https://weather-flag.netlify.com" target="_blank" rel="noopener noreferrer">
              <p className="project-link__headline">Tech stuck:</p>
              <div className="project-link__line"></div>
              <ul className="project-link__list">
                <li>React js</li>
                <li>Vanila js</li>
                <li>SASS</li>
                <li>CSS Grid</li>
                <li>CSS Animate</li>
                <li>Mediaquery(Mobile)</li>
              </ul>
            </a>

            <div className="image-container">
              <img src={weather} alt="Weather app" />
            </div>
            <p>Weather app</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projekte;
