export const sprachen = [
  {
    id: "dari-1",
    skill: "Dari / Farsi",

    fullStar: [
      { color: "lime", id: "full-dari-1" },
      { color: "lime", id: "full-dari-2" },
      { color: "lime", id: "full-dari-3" },
      { color: "lime", id: "full-dari-4" },
      { color: "lime", id: "full-dari-5" },
    ],
    emptyStar: [],
  },

  {
    id: "englisch-1",
    skill: "Englisch",

    fullStar: [
      { color: "lime", id: "full-englisch-1" },
      { color: "lime", id: "full-englisch-2" },
      { color: "lime", id: "full-englisch-3" },
      { color: "lime", id: "full-englisch-4" },
    ],
    emptyStar: [{ color: "#DFE0E0", id: "empty-css-1" }],
  },

  {
    id: "deutsch-1",
    skill: "Deutsch",

    fullStar: [
      { color: "lime", id: "full-deutsch-1" },
      { color: "lime", id: "full-deutsch-2" },
      { color: "lime", id: "full-deutsch-3" },
      { color: "lime", id: "full-deutsch-4" },
    ],
    emptyStar: [{ color: "#DFE0E0", id: "empty-css-1" }],
  },

  {
    id: "arabisch-1",
    skill: "Arabisch",

    fullStar: [
      { color: "lime", id: "full-arabisch-1" },
      { color: "lime", id: "full-arabisch-2" },
      { color: "lime", id: "full-arabisch-3" },
    ],
    emptyStar: [
      { color: "#DFE0E0", id: "empty-arabisch-1" },
      { color: "#DFE0E0", id: "empty-arabisch-2" },
    ],
  },
];
