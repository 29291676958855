import React from "react";
import { skills } from "../data/skills";
import { sprachen } from "../data/sprachen";
import { ReactComponent as Logo } from "../img/SVG/star.svg";

function Abilities(props) {
  return (
    <div id="fähigkeiten">
      <div className="experiences abilities">
        <div className="experiences__headline">Fähigkeiten</div>
        <div className="experiences__border-bottom"></div>
        <div className="experiences__title abilities__title">Kenntnisse</div>
      </div>
      <div className="skillsContainer">
        {skills.map((elem) => {
          return (
            <div key={elem.id} className="skills">
              <p className="skills__name">{elem.skill}</p>
              <ul className="skills__iconContainer">
                {elem.fullStar.map((f) => {
                  return (
                    <li key={f.id}>
                      <Logo fill={f.color} className="skills__icon" />
                    </li>
                  );
                })}

                {elem.emptyStar.map((e) => {
                  return (
                    <li key={e.id}>
                      <Logo fill={e.color} className="skills__icon" />
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>

      <div className="experiences abilities">
        <div className="experiences__headline"></div>
        <div className="experiences__border-bottom"></div>
        <div className="experiences__title abilities__title">Sprache</div>
      </div>

      <div className="skillsContainer">
        {sprachen.map((elem) => {
          return (
            <div key={elem.id} className="skills">
              <p className="skills__name">{elem.skill}</p>
              <ul className="skills__iconContainer">
                {elem.fullStar.map((f) => {
                  return (
                    <li key={f.id}>
                      <Logo fill={f.color} className="skills__icon" />
                    </li>
                  );
                })}

                {elem.emptyStar.map((e) => {
                  return (
                    <li key={e.id}>
                      <Logo fill={e.color} className="skills__icon" />
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Abilities;
