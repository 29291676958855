export const skills = [
  {
    id: "html-1",
    skill: "HTML(5)",

    fullStar: [
      { color: "lime", id: "full-html-1" },
      { color: "lime", id: "full-html-2" },
      { color: "lime", id: "full-html-3" },
      { color: "lime", id: "full-html-4" },
    ],
    emptyStar: [{ color: "#DFE0E0", id: "empty-html-1" }],
  },

  {
    id: "css-1",
    skill: "CSS(3)",

    fullStar: [
      { color: "lime", id: "full-css-1" },
      { color: "lime", id: "full-css-2" },
      { color: "lime", id: "full-css-3" },
      { color: "lime", id: "full-css-4" },
    ],
    emptyStar: [{ color: "#DFE0E0", id: "empty-css-1" }],
  },

  {
    id: "sass-1",
    skill: "SASS / SCSS",

    fullStar: [
      { color: "lime", id: "full-sass-1" },
      { color: "lime", id: "full-sass-2" },
      { color: "lime", id: "full-sass-3" },
      { color: "lime", id: "full-sass-4" },
    ],
    emptyStar: [{ color: "#DFE0E0", id: "empty-sass-1" }],
  },

  {
    id: "javaScript-1",
    skill: "JavaScript",

    fullStar: [
      { color: "lime", id: "full-javaScript-1" },
      { color: "lime", id: "full-javaScript-2" },
      { color: "lime", id: "full-javaScript-3" },
      { color: "lime", id: "full-javaScript-4" },
    ],
    emptyStar: [{ color: "#DFE0E0", id: "empty-javaScript-1" }],
  },

  {
    id: "react-1",
    skill: "React js",

    fullStar: [
      { color: "lime", id: "full-react-1" },
      { color: "lime", id: "full-react-2" },
      { color: "lime", id: "full-react-3" },
      { color: "lime", id: "full-react-4" },
    ],
    emptyStar: [{ color: "#DFE0E0", id: "empty-react-1" }],
  },

  {
    id: "bootstrap-1",
    skill: "Bootstrap",

    fullStar: [
      { color: "lime", id: "full-bootstrap-1" },
      { color: "lime", id: "full-bootstrap-2" },
      { color: "lime", id: "full-bootstrap-3" },
    ],
    emptyStar: [
      { color: "#DFE0E0", id: "empty-bootstrap-1" },
      { color: "#DFE0E0", id: "empty-bootstrap-2" },
    ],
  },

  {
    id: "react-redux-1",
    skill: "React-Redux",

    fullStar: [
      { color: "lime", id: "full-react-redux-1" },
      { color: "lime", id: "full-react-redux-2" },
      { color: "lime", id: "full-react-redux-3" },
    ],
    emptyStar: [
      { color: "#DFE0E0", id: "empty-react-redux-1" },
      { color: "#DFE0E0", id: "empty-react-redux-2" },
    ],
  },

  {
    id: "node-1",
    skill: "Node js",

    fullStar: [
      { color: "lime", id: "full-node-1" },
      { color: "lime", id: "full-node-2" },
      { color: "lime", id: "full-node-3" },
    ],
    emptyStar: [
      { color: "#DFE0E0", id: "empty-node-1" },
      { color: "#DFE0E0", id: "empty-node-2" },
    ],
  },

  {
    id: "express-1",
    skill: "Express js",

    fullStar: [
      { color: "lime", id: "full-express-1" },
      { color: "lime", id: "full-express-2" },
      { color: "lime", id: "full-express-3" },
    ],
    emptyStar: [
      { color: "#DFE0E0", id: "empty-express-1" },
      { color: "#DFE0E0", id: "empty-express-2" },
    ],
  },

  {
    id: "mongodb-1",
    skill: "MongoDB",

    fullStar: [
      { color: "lime", id: "full-mongodb-1" },
      { color: "lime", id: "full-mongodb-2" },
      { color: "lime", id: "full-mongodb-3" },
    ],
    emptyStar: [
      { color: "#DFE0E0", id: "empty-mongodb-1" },
      { color: "#DFE0E0", id: "empty-mongodb-2" },
    ],
  },

  {
    id: "jquery-1",
    skill: "jQuery",

    fullStar: [
      { color: "lime", id: "full-jquery-1" },
      { color: "lime", id: "full-jquery-2" },
      { color: "lime", id: "full-jquery-3" },
    ],
    emptyStar: [
      { color: "#DFE0E0", id: "empty-jquery-1" },
      { color: "#DFE0E0", id: "empty-jquery-2" },
    ],
  },

  {
    id: "bem-1",
    skill: "BEM",

    fullStar: [
      { color: "lime", id: "full-bem-1" },
      { color: "lime", id: "full-bem-2" },
      { color: "lime", id: "full-bem-3" },
    ],
    emptyStar: [
      { color: "#DFE0E0", id: "empty-bem-1" },
      { color: "#DFE0E0", id: "empty-bem-2" },
    ],
  },
];
