import React from "react";

function Experiences(props) {
  return (
    <div className="experiences" id="erfahrungen">
      <div className="experiences__headline">Erfahrungen</div>
      <div className="experiences__border-bottom"></div>
      <div className="experiences__title">Weiterbildungen</div>

      <div className="education">
        <p className="education__school">Code with Mosh</p>
        <p className="education__subject">Nodejs</p>
        <p className="education__date">09.2020 - 10.2020</p>
        <p className="education__description">
          Arbeiten mit Node.js, Express.js, Mongoose und MongoDB. Aufbauen
          fullstack Projekte.
        </p>
        <p className="education__online">online</p>
      </div>

      <div className="education">
        <p className="education__school">Code with Mosh</p>
        <p className="education__subject">Reactjs</p>
        <p className="education__date">07.2020 - 08.2020</p>
        <p className="education__description">
          Arbeiten mit React.js, React-Router, Components, React Hook, Joi
          validation. Aufbauen React Apps mit React.js.
        </p>
        <p className="education__online">online</p>
      </div>

      <div className="education">
        <p className="education__school">Code with Mosh</p>
        <p className="education__subject">JavaScript</p>
        <p className="education__date">05.2020 - 05.2020</p>
        <p className="education__description">
          Objektorientierte Programmierung in JavaScript. JavaScript Funktionen
          und Classes. Arbeiten mit Array und Objekte in JavaScript.
        </p>
        <p className="education__online">online</p>
      </div>

      <div className="education">
        <p className="education__school">Udemy</p>
        <p className="education__subject">Advanced CSS and SASS</p>
        <p className="education__date">02.2020 - 03.2020</p>
        <p className="education__description">
          CSS-Grund- und Blockelemente. Flexbox, Grid. Arbeiten mit SVG,
          responsive Design, Mediaquery. BEM Mode. SASS und ... .
        </p>
        <p className="education__online">online</p>
      </div>

      <div className="education">
        <p className="education__school">Digital Career Institute</p>
        <p className="education__subject">Webentwicklung</p>
        <p className="education__date">06.2018 - 06.2019</p>
        <p className="education__description">
          Grundlegend für die full stack Webentwicklung. HTML, CSS, SASS,
          JavaScript, jQuery, Bootstrap, React.js, Node.js, MongoDB und ... .
        </p>
        <p className="education__online">Presenz</p>
      </div>
    </div>
  );
}

export default Experiences;
