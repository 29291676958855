import React from "react";
import profileFoto from "../img/myfoto8.jpeg";
import letterA from "../img/letter-a.svg";

function FotoSection(props) {
  return (
    <div className="fotoSection" id="profile">
      <div className="fotoSection__texts">
        <div className="fotoSection__texts--circle">
          <img src={letterA} alt="" />
        </div>
        <p className="fotoSection__texts--nameTop">Ali Pudina</p>
        <p className="fotoSection__texts--webentwicklerTop">Webentwickler</p>
        <p className="fotoSection__texts--nameMain">Ali Pudina</p>
        <p className="fotoSection__texts--webentwicklerMain">Webentwickler</p>
        <p className="fotoSection__texts--description">

          Ich bin sehr technikbegeistert und strebe dementsprechend eine Tätigkeit im IT-Bereich an.
          Nach einer Weiterbildungsmaßnahme im Bereich Webentwicklung absolviere ich derzeitig eine 
          Umschulung zum Fachinformatiker "Systemintegration" am CBW-College für Berufliche Weiterbildung, 
          Hamburg.
          <br />
          Neben den Grundlagen der Administration und Einrichtung von IT Systemen ist ein weiterer 
          Schwerpunkt meiner Weiterbildung der Umgang mit Cloud-Umgebungen.

          <br /> <br />
          Ich freue mich auf Ihre Kontaktaufnahme (über die Schaltfläche "Kontakt").
          
        </p>
      </div>
      <div className="fotoSection__fotoContainer">
        <img src={profileFoto} alt="" />
      </div>
    </div>
  );
}

export default FotoSection;

//<div>Icons made by <a href="http://www.freepik.com/" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
